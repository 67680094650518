@font-face {
    font-family: "cs";
    src: url(../fonts/changaone-regular.ttf);
}

@font-face {
    font-family: "bb";
    src: url(../fonts/baby\ blocks.ttf);
}

a {
    color: inherit;
    text-decoration: none;
}

.menu {
    position: fixed;
    bottom: 0;
    display: block;
    /* flex-direction: row; */
    width: 100%;
    /* justify-content: space-around; */
    /* flex-wrap: wrap; */
    height: 50px;
    margin: 1em auto;
    /* line-height: 10px; */
    text-align:center;
}

.menuitem { 
    font-family: "bb";
    display:block;
    font-size: 1em;
    width: 40%;
    display: inline-block;
    /* font-size: 1em;     */
    /* width: 100px; */
    /* text-align: center; */
    /* float:left; */
    /* animation-play-state: paused; */
}

.menuitem:hover {
    animation-play-state: running;
    animation-iteration-count: infinite;
    animation: "fadeOut";
}


@media only screen and (min-width: 650px) {
    .menuitem {
        font-size: 1.5em;
        display: inline;
    }
    .menu {
        height: 50px;
    }
    .menuitem:nth-child(even) {
        position: relative;
        top: 1em;
    }
    
    .menuitem:nth-child(odd) {
        position: relative;
        top: -0.5em;
    }   
}