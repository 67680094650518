body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, #root, body {
  height: 100%;
}


body {
  background-color: black;
}
.main {
  background-color: black;
  height: 100%;
  color: white;
  /* margin: 0; */
}
  
.header {
  display:block;
  text-align: center;
  /* position: relative; */
  padding-top: 10px;
  /* top: 2vh; */
  height: 10%;
  overflow: none;
  /* margin-top: 2vh; */
  /* height: 50px; */
}

.header img, .header video {
  max-width: 100%;
  max-height: 100%;
}

.content {
  overflow: auto;
  height: 74%;
  padding-top: 10px;
  /* display: inline-block; */
  text-align: center;
  /* height: calc(100vh - 75px); */
  width: 80%;
  margin: auto;
  /* left: calc(80vw-80%); */
  /* overflow-y: scroll; */
}

.content:after,
.contentblock {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}


/* vertically centering components */
.verticalmid {
  display: inline-block;
  vertical-align: middle;
  height: 80%;
  width: 100%;
  padding: none;
  margin: auto;
}

.verticalmid:after {
  display: inline-block;
  content: '';
  /* width: 5px; */
  /* background-color: red; */
  height: 100%;
  height: inherit;
  vertical-align: middle;
}

.verticalmid img, .verticalmid form{
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
/* end vertically centering components */


.contentblock img {
  /* height: 100%; */
  max-width: 95%;
  max-height: 100%;
  /* max-width: 100%; */
  /* max-height:60%; */
}
/* .informations {
  display: inline-block;
  height: 100%;
} */

.information {
  display: inline-block;
  font-family: "bb";
  font-size: 2em;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
}

form {
  /* height: 100%;
   */
   width: 100%;
}

form label {
  font-family: "bb";
  font-size: 1em;
}

/* #signup-form label {
  font-size: 1em;
} */

form p {
  font-family: "bb";
  color: red;
  text-transform: lowercase;
}

form input {
  width: 40%;
  /* max-width: 70%; */
  height: 2em;
  font-size: x-large;
  font-family: "bb";
  background-color: white;
  text-align: center;
  border: none;
}

form input[type=password] {
  font-family: Arial, Helvetica, sans-serif;
}

.mosaic {
  /* background-color: white; */
  display:inline-block;
  width: 100%;
  height: 98%;
  background-image: url(/static/media/cs_header_invert.909fc374.gif);
  background-repeat: repeat;
  background-size: 75px 50px;
}

.stripe-button-el span, .stripe-button-el{
  font-family:"bb" !important;
  font-size: 1em !important;
  color: black !important;
  border: none !important;
  background: none !important;
  background-color: white!important;
  -webkit-box-shadow: none !important;
  text-transform: lowercase;
  /* display: hidden !important; */
}
/* .stripe-button-el span:after {
  content:"hello";
  display:visible !important;
} */

@media screen and (max-width: 450px) {
  form label {
    font-size: 2em;
  }
  form input {
    width: 80%;
  }
  .information {
    width: 80%;
    font-size: 1em;
  }
}

.joinForm { 
  height: 100%;
}

.success {
  color: greenyellow;
}

.error {
  color: red;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
	
	background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent));
}

/* .content-img:after  { 
  content: "";
  height: 100%;
  width: 0px;
  display: inline-block;
  vertical-align: middle;
} */
/* .header:before{
  content: "s"
} */
/* #App-header {
  height: 100%;
  width: 100%;
  background-color: black;
} */


/* .main {
  background-color: black;
} */

/* @media screen and (min-width: 650px) { */
  /* .content {
    height: calc(100vh - 250px); 
  }
  .header {
    height: 110px;
  } */
  /* .header img { 
    height: 70px;
  } */
/* } */
@font-face {
    font-family: "cs";
    src: url(/static/media/changaone-regular.eaf97b4f.ttf);
}

@font-face {
    font-family: "bb";
    src: url("/static/media/baby blocks.4567f92d.ttf");
}

a {
    color: inherit;
    text-decoration: none;
}

.menu {
    position: fixed;
    bottom: 0;
    display: block;
    /* flex-direction: row; */
    width: 100%;
    /* justify-content: space-around; */
    /* flex-wrap: wrap; */
    height: 50px;
    margin: 1em auto;
    /* line-height: 10px; */
    text-align:center;
}

.menuitem { 
    font-family: "bb";
    display:block;
    font-size: 1em;
    width: 40%;
    display: inline-block;
    /* font-size: 1em;     */
    /* width: 100px; */
    /* text-align: center; */
    /* float:left; */
    /* animation-play-state: paused; */
}

.menuitem:hover {
    -webkit-animation-play-state: running;
            animation-play-state: running;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation: "fadeOut";
            animation: "fadeOut";
}


@media only screen and (min-width: 650px) {
    .menuitem {
        font-size: 1.5em;
        display: inline;
    }
    .menu {
        height: 50px;
    }
    .menuitem:nth-child(even) {
        position: relative;
        top: 1em;
    }
    
    .menuitem:nth-child(odd) {
        position: relative;
        top: -0.5em;
    }   
}
